//@author: devin

import { NgModule } from "@angular/core";
import { S25DropdownPaginatedComponent } from "./s25.dropdown.paginated.component";
import { S25DropdownItemComponent } from "./s25.dropdown.item.component";
import { CommonModule } from "@angular/common";
import { S25FavoriteModule } from "../s25-favorite/s25.favorite.module";
import { S25ItemModule } from "../s25-item/s25.item.module";
import { FormsModule } from "@angular/forms";
import { S25DropdownTestComponent } from "./s25.dropdown.test.component";
import { S25TypeaheadModule } from "../s25-typeahead/s25.typeahead.module";
import { S25InfiniteScrollModule } from "../s25-infinite-scroll/s25.infinite.scroll.module";
import { S25GenericDropdownComponent } from "./generic/s25.generic.dropdown.component";
import { S25BpeVarsDropdownComponent } from "./single-select/s25.bpe.vars.dropdown.component";
import { S25EventTypeDropdownComponent } from "./single-select/s25.event.type.dropdown.component";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25LocationDropdownComponent } from "./single-select/s25.location.dropdown.component";
import { S25CategoryDropdownComponent } from "./single-select/s25.category.dropdown.component";
import { S25ContactDropdownComponent } from "./single-select/s25.contact.dropdown.component";
import { S25CustomAttrDropdownComponent } from "./single-select/s25.custom.attr.dropdown.component";
import { S25CustomAttrDropdownAdvSearchComponent } from "./single-select/s25.custom.attr.dropdown.adv.search.component";
import { S25SearchOperatorsDropdownComponent } from "./single-select/s25.search.operators.dropdown.component";
import { S25ResourceDropdownComponent } from "./single-select/s25.resource.dropdown.component";
import { S25OrganizationDropdownComponent } from "./single-select/s25.organization.dropdown.component";
import { S25FormulasDropdownComponent } from "./single-select/s25.formulas.dropdown.component";
import { S25TaxSchedulesDropdownComponent } from "./single-select/s25.tax.schedules.dropdown.component";
import { S25RateGroupDropdownComponent } from "./single-select/s25.rate.group.dropdown.component";
import { S25RoleDropdownComponent } from "./single-select/s25.role.dropdown.component";
import { S25DropdownSearchCriteriaComponent } from "./single-select/s25.dropdown.search.criteria.component";
import { S25PartitionDropdownComponent } from "./single-select/s25.partition.dropdown.component";
import { S25SearchDropdownComponent } from "./single-select/s25.search.dropdown.component";
import { S25GenericMultiselectDropdownComponent } from "./generic/s25.generic.multiselect.dropdown.component";
import { S25DropdownMultiSearchCriteriaComponent } from "./multi-select/s25.dropdown.multi.search.criteria.component";
import { S25DropdownMultiRateGroupDropdown } from "./multi-select/s25.dropdown.multi.rate.group.component";
import { S25HierarchyDropdownComponent } from "./single-select/s25.hierarchy.dropdown.component";
import { S25FolderTypeDropdownComponent } from "./single-select/s25.folder.type.dropdown.component";
import { S25SubfolderDropdownComponent } from "./single-select/s25.subfolders.dropdown.component";
import { S25DropdownMultiContactsComponent } from "./multi-select/s25.dropdown.multi.contacts.component";
import { S25DropdownMultiRelationshipsComponent } from "./multi-select/s25.dropdown.multi.relationships.component";
import { S25DropdownAbstract } from "./s25.dropdown.abstract";
import { S25SeasonDropdownComponent } from "./single-select/s25.season.dropdown.component";
import { S25ButtonComponent } from "../../standalone/s25.button.component";

@NgModule({
    declarations: [
        S25DropdownAbstract,
        S25DropdownSearchCriteriaComponent,
        S25DropdownPaginatedComponent,
        S25DropdownItemComponent,
        S25DropdownTestComponent,
        S25GenericDropdownComponent,
        S25BpeVarsDropdownComponent,
        S25EventTypeDropdownComponent,
        S25LocationDropdownComponent,
        S25CategoryDropdownComponent,
        S25ContactDropdownComponent,
        S25CustomAttrDropdownComponent,
        S25CustomAttrDropdownAdvSearchComponent,
        S25SearchOperatorsDropdownComponent,
        S25ResourceDropdownComponent,
        S25OrganizationDropdownComponent,
        S25FormulasDropdownComponent,
        S25TaxSchedulesDropdownComponent,
        S25RateGroupDropdownComponent,
        S25RoleDropdownComponent,
        S25PartitionDropdownComponent,
        S25SearchDropdownComponent,
        S25GenericMultiselectDropdownComponent,
        S25DropdownMultiSearchCriteriaComponent,
        S25DropdownMultiRateGroupDropdown,
        S25HierarchyDropdownComponent,
        S25FolderTypeDropdownComponent,
        S25SubfolderDropdownComponent,
        S25DropdownMultiContactsComponent,
        S25DropdownMultiRelationshipsComponent,
        S25SeasonDropdownComponent,
    ],
    imports: [
        CommonModule,
        S25FavoriteModule,
        S25ItemModule,
        FormsModule,
        S25TypeaheadModule,
        S25InfiniteScrollModule,
        S25LoadingInlineModule,
        S25ButtonComponent,
    ],
    providers: [
        S25DropdownItemComponent,
        S25DropdownSearchCriteriaComponent,
        S25DropdownPaginatedComponent,
        S25DropdownTestComponent,
        S25GenericDropdownComponent,
        S25BpeVarsDropdownComponent,
        S25EventTypeDropdownComponent,
        S25LocationDropdownComponent,
        S25CategoryDropdownComponent,
        S25ContactDropdownComponent,
        S25BpeVarsDropdownComponent,
        S25LocationDropdownComponent,
        S25CustomAttrDropdownComponent,
        S25CustomAttrDropdownAdvSearchComponent,
        S25GenericDropdownComponent,
        S25SearchOperatorsDropdownComponent,
        S25ResourceDropdownComponent,
        S25OrganizationDropdownComponent,
        S25FormulasDropdownComponent,
        S25TaxSchedulesDropdownComponent,
        S25RateGroupDropdownComponent,
        S25RoleDropdownComponent,
        S25BpeVarsDropdownComponent,
        S25PartitionDropdownComponent,
        S25SearchDropdownComponent,
        S25GenericMultiselectDropdownComponent,
        S25DropdownTestComponent,
        S25DropdownMultiSearchCriteriaComponent,
        S25DropdownMultiRateGroupDropdown,
        S25HierarchyDropdownComponent,
        S25FolderTypeDropdownComponent,
        S25SubfolderDropdownComponent,
        S25DropdownMultiContactsComponent,
        S25DropdownMultiRelationshipsComponent,
        S25SeasonDropdownComponent,
    ],
    exports: [
        S25DropdownSearchCriteriaComponent,
        S25DropdownPaginatedComponent,
        S25CategoryDropdownComponent,
        S25ContactDropdownComponent,
        S25BpeVarsDropdownComponent,
        S25LocationDropdownComponent,
        S25CustomAttrDropdownComponent,
        S25CustomAttrDropdownAdvSearchComponent,
        S25GenericDropdownComponent,
        S25SearchOperatorsDropdownComponent,
        S25ResourceDropdownComponent,
        S25OrganizationDropdownComponent,
        S25FormulasDropdownComponent,
        S25TaxSchedulesDropdownComponent,
        S25RateGroupDropdownComponent,
        S25RoleDropdownComponent,
        S25BpeVarsDropdownComponent,
        S25PartitionDropdownComponent,
        S25SearchDropdownComponent,
        S25GenericMultiselectDropdownComponent,
        S25DropdownTestComponent,
        S25DropdownMultiSearchCriteriaComponent,
        S25DropdownMultiRateGroupDropdown,
        S25HierarchyDropdownComponent,
        S25FolderTypeDropdownComponent,
        S25SubfolderDropdownComponent,
        S25DropdownMultiContactsComponent,
        S25DropdownMultiRelationshipsComponent,
        S25SeasonDropdownComponent,
    ],
})
export class S25DropdownPaginatedModule {}
